import { VehicleTagScheme } from '../app/pages/interfaces';

export const vehicleTagSchemeList = [
    { name: 'Retail', value: VehicleTagScheme.Retail },
    { name: 'Fleet+', value: VehicleTagScheme.FleetPlus },
    { name: 'ISO', value: VehicleTagScheme.ISO },
    { name: 'ISO_Tab', value: VehicleTagScheme.ISO_Tab },
];

export const DEBOUNCE_INPUT_FIELD_TIME = 700;
