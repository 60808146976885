import {
    BadgeOutlined,
    BubbleChart,
    CreditCard,
    CreditScore,
    Dashboard,
    DirectionsCar,
    DocumentScanner,
    Download,
    Email,
    EvStation,
    Groups,
    GroupWork,
    LocalParking,
    LocationCity,
    ManageAccountsRounded,
    MonetizationOn,
    ReceiptLong,
    SettingsSuggest,
    Star,
    Storefront,
    Domain,
} from '@mui/icons-material';

import { MenuType } from '../interfaces';

import { ReactComponent as PointsIcon } from '../../../assets/images/points_icon.svg';
import { ReactComponent as TwintIcon } from '../../../assets/images/twint_icon.svg';

//menu entries for the same module should have ascending keys
export const MenuEntries: MenuType[] = [
    {
        key: '1',
        type: 'menu',
        title: 'services.dashboard.demoChart',
        path: '/dashboard',
        icon: <Dashboard />,
    },
    {
        key: '2',
        type: 'menu',
        title: 'services.manageUsers.users',
        path: '/users',
        icon: <Groups />,
    },
    {
        type: 'menu',
        title: 'services.manageMandators.mandators',
        key: '3',
        path: '/mandators',
        icon: <LocationCity />,
    },
    {
        type: 'menu',
        title: 'services.manageSites.productsAssignment',
        key: '4',
        path: '/products-assignment',
        icon: <BubbleChart />,
    },
    {
        type: 'subMenu',
        title: 'services.manageSites.sites',
        key: '5',
        path: '/sites',
        icon: <DirectionsCar />,
        subMenu: [
            {
                type: 'menu',
                title: 'services.manageSites.devices',
                key: '5-1',
                path: '/devices',
                icon: <SettingsSuggest />,
            },
            {
                type: 'menu',
                title: 'services.manageSites.points',
                key: '5-2',
                path: '/points',
                icon: <PointsIcon fill="var(--secondary-color)" />,
            },
        ],
    },
    {
        type: 'menu',
        title: 'services.manageSites.prices',
        key: '6',
        path: '/prices',
        icon: <MonetizationOn />,
    },
    {
        key: '7',
        type: 'menu',
        title: 'services.manageTransactions.transactions',
        path: '/transactions',
        icon: <CreditCard />,
    },
    {
        key: '8',
        type: 'menu',
        title: 'services.manageUserPermissions.userPermissions',
        path: '/user-permissions',
        icon: <ManageAccountsRounded />,
    },
    {
        key: '9',
        type: 'menu',
        title: 'services.manageManufacturers.manufacturers',
        path: '/charging-stations',
        icon: <EvStation />,
    },
    {
        key: '10',
        type: 'menu',
        title: 'services.manageCards.cards',
        path: '/cards',
        icon: <CreditCard />,
    },
    {
        key: '13',
        type: 'subMenu',
        title: 'services.manageProviderTemplates.providerTemplates',
        path: '/provider-templates',
        icon: <GroupWork />,
        subMenu: [
            {
                type: 'menu',
                title: 'services.manageProviderTemplates.providerFields',
                key: '13-1',
                path: '/provider-fields',
                icon: <GroupWork />,
            },
        ],
    },
    {
        key: '14',
        type: 'menu',
        title: 'services.manageFleetnets.fleetnet',
        path: '/fleetnet',
        icon: <CreditScore />,
    },
    {
        key: '15',
        type: 'menu',
        title: 'services.manageCardProfiles.cardProfiles',
        path: '/card-profiles',
        icon: <BadgeOutlined />,
    },
    {
        key: '16',
        type: 'menu',
        title: 'services.manageProducts.products',
        customTitle: 'services.manageProducts.title',
        path: '/products',
        icon: <BubbleChart />,
    },
    {
        key: '17',
        type: 'menu',
        title: 'services.manageMarketplace.manageMarketplace',
        path: '/manage-marketplace',
        icon: <Storefront />,
    },
    {
        key: '18',
        type: 'menu',
        title: 'services.marketplace.marketplace',
        path: '/marketplace',
        icon: <Storefront />,
    },
    {
        key: '19',
        type: 'subMenu',
        title: 'services.manageDigitalReceipts.digitalReceipt',
        path: '/digital-receipts',
        icon: <ReceiptLong />,
        subMenu: [
            {
                type: 'menu',
                title: 'services.manageDigitalReceipts.siteAssignments',
                key: '19-1',
                path: '/receipt-site-assignments',
                icon: <ReceiptLong />,
            },
            {
                type: 'menu',
                title: 'services.manageDigitalReceipts.emailTemplates',
                key: '19-2',
                path: '/receipt-email-delivery',
                icon: <Email />,
            },
        ],
    },
    {
        key: '20',
        type: 'menu',
        title: 'services.manageTwint.twint',
        path: '/twint',
        icon: <TwintIcon />,
    },
    {
        key: '21',
        type: 'subMenu',
        title: 'services.manageFleets.fleets',
        path: '/fleetnet-sites-devices',
        icon: <CreditScore />,
        subMenu: [
            {
                type: 'menu',
                title: 'services.manageFleets.fleetnetSites',
                key: '21-1',
                path: '/fleetnet-sites',
                icon: <DirectionsCar />,
            },
            {
                type: 'menu',
                title: 'services.manageFleets.fleetnetDevices',
                key: '21-2',
                path: '/fleetnet-devices',
                icon: <SettingsSuggest />,
            },
        ],
    },
    {
        key: '22',
        type: 'subMenu',
        title: 'services.manageFreeParking.freeParking',
        path: '/free-parking',
        icon: <LocalParking />,
        subMenu: [
            {
                type: 'menu',
                title: 'services.manageFreeParking.siteAssignments',
                key: '22-1',
                path: '/configuration-assignments',
                icon: <DirectionsCar />,
            },
        ],
    },
    {
        key: '23',
        type: 'menu',
        title: 'services.manageReports.reports',
        path: '/reports',
        icon: <Download />,
    },
    {
        key: '24',
        type: 'subMenu',
        title: 'services.manageEdentify.edentify',
        path: '/edentify',
        icon: <DocumentScanner />,
        subMenu: [
            {
                type: 'menu',
                title: 'services.manageEdentify.units',
                key: '24-1',
                path: '/edentify-units',
                icon: <Star />,
            },
            {
                type: 'menu',
                title: 'services.manageEdentify.vehicleTags',
                key: '24-2',
                path: '/vehicle-tags',
                icon: <Star />,
            },
        ],
    },
    {
        type: 'subMenu',
        title: 'services.manageProductAndPrices.productAndPrices',
        key: '25',
        path: '/configuration/products-prices',
        icon: <Domain />,
        subMenu: [
            {
                type: 'menu',
                title: 'services.manageProductAndPrices.manageProducts.products',
                key: '25-1',
                path: '/configuration/products-prices/products',
                icon: <BubbleChart />,
            },
        ],
    },
];
