import { useEffect, useState, MouseEvent, MutableRefObject } from 'react';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation, TFunction } from 'react-i18next';
import {
    AddBoxRounded,
    AddLink,
    AddBox,
    ContentCopy,
    DeleteForever,
    Download,
    Edit,
    Print,
    ReceiptLong,
    Search,
} from '@mui/icons-material';
import { Box, Menu, MenuItem, IconButton, Link } from '@mui/material';
import { GridCellProps } from '@progress/kendo-react-grid';
import { TooltipButton } from '../..';
import {
    useAppDispatch,
    useAppSelector,
    useDownloadFile,
    useRights,
    useWindowSize,
} from '../../../hooks';
import { displayModal } from '../../modal/modalDuck';
import { Rights } from '../../interfaces';
import './actionButtonsCell.scss';
import {
    ACTION_BUTTONS_VISIBILITY,
    Actions,
    BREAKPOINTS,
    MODAL_TYPES,
} from '../../../../constants';
import { closeModal } from '../../../../helpers';
import { getAssignmentsForSite } from '../../../pages/digitalReceipts/siteAssignments/siteAssignmentsDuck';
import { TemplateStateTypes } from '../../../pages/interfaces';
interface ActionButtonCellProps<T> extends GridCellProps {
    component: string;
    index: string;
    path: string;

    buttonsVisibility?: Map<string, boolean>;
    entity?: string;
    itemToCopy?: string;
    parentPath?: string;
    copyGridRow?: (dataItem: T) => void;
    customAddAction?: (dataItem?: T) => void;
    customAddNewRowAction?: (dataItem?: T) => void;
    deleteEntity?: (dataItem: T) => void;
    editGridRow?: (dataItem: T) => void;
    onErrorDownloadFile?: () => void;
    postDownloading?: () => void;
    preDownloading?: () => void;
    readFile?: (val: string) => Promise<AxiosResponse<any, any>>;
}

const generateIcon = (action: string) => {
    switch (action) {
        case Actions.Link:
            return <AddLink />;
        case Actions.Add:
            return <AddBoxRounded />;
        case Actions.View:
            return <Search />;
        case Actions.Edit:
            return <Edit />;
        case Actions.Delete:
            return <DeleteForever />;
        case Actions.Copy:
            return <ContentCopy />;
        case Actions.CopyItem:
            return <ContentCopy />;
        case Actions.Receipt:
            return <ReceiptLong />;
        case Actions.Print:
            return <Print />;
        case Actions.Download:
            return <Download />;
        case Actions.AddRow:
            return <AddBox />;
    }
};

const getButtonElement = (
    buttonType: string,
    actionButtonsEvents: Map<string, () => void>,
    itemToCopy?: string,
    isMenu?: boolean,
    entity?: string,
    url?: string,
    name?: string,
    ref?: MutableRefObject<HTMLAnchorElement | null>
) => {
    const handleClickEvent = !isMenu ? actionButtonsEvents.get(buttonType) : undefined;
    let link = null;
    if (buttonType === 'receipt') {
        link = <Link href={url} download={name} sx={{ display: 'none' }} ref={ref} />;
    }

    return (
        <>
            {link}
            <TooltipButton
                role={`${buttonType}-button`}
                title={`general.labels.${buttonType}`}
                entity={entity ?? itemToCopy}
                icon={generateIcon(buttonType)}
                onClick={handleClickEvent}
            />
        </>
    );
};

const actionUponGridRow = <F extends (arg: T) => void, T>(callback?: F, dataItem?: T): boolean => {
    if (callback && dataItem) {
        callback(dataItem);
        return true;
    }
    return false;
};

const getFileName = (fileUrl = '') => {
    const fileName = fileUrl.replace(/^.*[\\/]/, '');
    if (fileName === '') return `${new Date().toISOString()}.pdf`;
    return `${new Date()}_${fileName}`;
};

const showModuleStateModal = (
    dispatch: (action: any) => any,
    t: TFunction<'translation', undefined>
) => {
    dispatch(
        displayModal({
            showModal: true,
            title: t('general.labels.moduleNotEnabled'),
            type: MODAL_TYPES.ConfirmationMessage,
            message: t('general.messages.moduleNotEnabled', {
                module: t('services.manageDigitalReceipts.digitalReceipt'),
            }),
            isCancelButtonHidden: true,
            confirmCustomLabel: t('general.labels.ok'),
            onLeave: () => {
                closeModal();
            },
        })
    );
};

const showReceipt = async (
    digitalReceiptModuleStatus: boolean,
    path: string,
    id: string,
    dispatch: (action: any) => any,
    t: TFunction<'translation', undefined>,
    siteId: string
) => {
    if (digitalReceiptModuleStatus) {
        const siteAssignedTemplatesResponse = await dispatch(getAssignmentsForSite(siteId));
        const siteAssignedTemplates = siteAssignedTemplatesResponse.payload.data;
        if (siteAssignedTemplates.length > 0) {
            const activeTemplate = siteAssignedTemplates.find(
                (assignment: TemplateStateTypes) => assignment.status === true
            );
            if (activeTemplate) {
                window.open(`${path}/${id}`, '_blank', 'noopener,noreferrer');
            } else {
                showModuleStateModal(dispatch, t);
            }
        } else {
            showModuleStateModal(dispatch, t);
        }
    } else {
        showModuleStateModal(dispatch, t);
    }
};

/**
 * Renders a cell with action buttons for a grid row. The cell displays different buttons based on the provided props.
 *
 * @param {ActionButtonCellProps<T>} props - The props for the ActionButtonsCell component.
 * @param {string} props.path - The path for navigation.
 * @param {string} props.index - The index of the grid row.
 * @param {T} props.dataItem - The data item for the grid row.
 * @param {string} props.component - The component name.
 * @param {string} [props.entity] - The entity name (optional).
 * @param {string} [props.parentPath=''] - The parent path for navigation (optional).
 * @param {Map<string, boolean>} [props.buttonsVisibility] - The visibility of the action buttons (optional).
 * @param {string} [props.itemToCopy] - The item to copy (optional).
 * @param {(dataItem?: T) => void} [props.customAddAction] - The custom add action function (optional).
 * @param {(dataItem: T) => void} [props.copyGridRow] - The copy grid row function (optional).
 * @param {(dataItem: T) => void} [props.deleteEntity] - The delete entity function (optional).
 * @param {(dataItem: T) => void} [props.editGridRow] - The edit grid row function (optional).
 * @param {() => void} [props.preDownloading] - The pre-downloading function (optional).
 * @param {() => void} [props.postDownloading] - The post-downloading function (optional).
 * @param {() => void} [props.onErrorDownloadFile] - The error download file function (optional).
 * @param {(val: string) => Promise<AxiosResponse<Blob, any>>} [props.readFile] - The read file function (optional).
 * @return {JSX.Element} The rendered ActionButtonsCell component.
 */
const ActionButtonsCell = <T extends Record<never, never>>({
    buttonsVisibility,
    component,
    dataItem,
    entity,
    index,
    itemToCopy,
    parentPath = '',
    path,
    copyGridRow,
    customAddAction,
    customAddNewRowAction,
    deleteEntity,
    editGridRow,
    onErrorDownloadFile,
    postDownloading,
    preDownloading,
    readFile,
}: ActionButtonCellProps<T>): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { modules } = useAppSelector((state) => state.marketplace);
    const [actionButtonsVisibility, setActionButtonsVisibility] =
        useState(ACTION_BUTTONS_VISIBILITY);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const windowDimensions = useWindowSize();
    const dispatch = useAppDispatch();

    const rightsMap = new Map([['subComponent', component]]);
    const rights = useRights(rightsMap);

    const receiptPath = dataItem.receipt?._links?.customer_url;
    const { ref, url, download, name } = useDownloadFile({
        apiDefinition: () => {
            return readFile?.(receiptPath) as Promise<AxiosResponse<Blob, any>>;
        },
        preDownloading: preDownloading,
        postDownloading: postDownloading,
        onError: onErrorDownloadFile,
        fileName: getFileName(receiptPath),
    });
    const isMobileSize = windowDimensions.width <= BREAKPOINTS.xs;
    const open = Boolean(anchorEl);

    const actionRedirect = (actionType: string, redirect = true) => {
        const redirectPath = `${path}/${actionType}`;

        redirect && navigate(redirectPath);
    };

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        buttonsVisibility && setActionButtonsVisibility(buttonsVisibility);
    }, [buttonsVisibility]);

    const viewItemDetails = () => {
        actionRedirect('view');
    };

    const editItem = () => {
        return actionUponGridRow(editGridRow, dataItem) || actionRedirect('edit');
    };

    const deleteItem = () => {
        return actionUponGridRow(deleteEntity, dataItem) || actionRedirect('delete');
    };

    const copyDataItem = () => {
        return actionUponGridRow(copyGridRow, dataItem) || actionRedirect('copy');
    };

    const seeReceiptDetails = () => {
        const digitalReceiptModuleStatus = modules.filter(
            (digitalReceiptModule) => digitalReceiptModule.name === 'Digital Receipts'
        )[0]?.active;
        return showReceipt(
            digitalReceiptModuleStatus,
            path,
            dataItem.id,
            dispatch,
            t,
            dataItem.site_id
        );
    };

    const printTransaction = () => {
        window.open(receiptPath, 'PRINT');
        console.log('Print transaction');
    };

    const redirectToParent = () => {
        localStorage.setItem('idParent', dataItem[index]);
        navigate(parentPath);
    };

    const addItemDetails = () => {
        return actionUponGridRow(customAddAction, dataItem) || redirectToParent();
    };

    const addRow = () => {
        return customAddNewRowAction?.();
    };
    const openPopover = open ? 'true' : undefined;
    const popoverControls = open ? 'action-buttons-menu' : undefined;
    const actionButtonsEvents = new Map([
        ['add', addItemDetails],
        ['link', addItemDetails],
        ['view', viewItemDetails],
        ['edit', editItem],
        ['delete', deleteItem],
        ['copy', copyDataItem],
        ['copyItem', copyDataItem],
        ['receipt', seeReceiptDetails],
        ['print', printTransaction],
        ['download', download],
        ['addRow', addRow],
    ]);
    const renderElement = (buttonType: string, isMenu?: boolean, rightIndependent?: boolean) => {
        let buttonEl = getButtonElement(
            buttonType,
            actionButtonsEvents,
            itemToCopy,
            isMenu,
            entity,
            url,
            name,
            ref
        );

        if (isMenu) {
            buttonEl = (
                <MenuItem onClick={actionButtonsEvents.get(buttonType)}>
                    {buttonEl}
                    {t(`general.labels.${buttonType}`)}
                </MenuItem>
            );
        }
        if (!rightIndependent) {
            return (
                rights?.subComponent &&
                rights?.subComponent[buttonType as keyof Rights] &&
                actionButtonsVisibility?.get(buttonType) &&
                buttonEl
            );
        }
        return actionButtonsVisibility?.get(buttonType) && buttonEl;
    };

    return !isMobileSize ? (
        <Box className="action-buttons">
            {renderElement('add', false, true)}
            {renderElement('link', false, true)}
            {renderElement('view')}
            {renderElement('edit')}
            {renderElement('copy', false, true)}
            {renderElement('copyItem', false, true)}
            {renderElement('delete')}
            {renderElement('receipt', false, true)}
            {renderElement('print', false, true)}
            {renderElement('download', false, true)}
            {renderElement('addRow', false, true)}
        </Box>
    ) : (
        <Box>
            <IconButton
                id="display-action-buttons"
                aria-controls={popoverControls}
                aria-haspopup="true"
                aria-expanded={openPopover}
                onClick={handleClick}>
                <span className="k-icon k-font-icon k-i-more-vertical"></span>
            </IconButton>
            <Menu
                id="action-buttons-menu"
                className="action-buttons-menu-list"
                aria-labelledby="display-action-buttons"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                {renderElement('add', true)}
                {renderElement('link', true)}
                {renderElement('view', true)}
                {renderElement('edit', true)}
                {renderElement('copy', true, true)}
                {renderElement('copyItem', true, true)}
                {renderElement('delete', true)}
                {renderElement('receipt', true, true)}
                {renderElement('print', true, true)}
                {renderElement('download', true, true)}
            </Menu>
        </Box>
    );
};
export default ActionButtonsCell;
